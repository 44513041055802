.navBar-container{
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white;
    padding-left: 5em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.nav-img{
    height: 8em;
}

.nav-links-container{
    margin-right: 12em;
}

.nav-links{
    margin: 0.5em;
    color: black;
    font-size: 18px;
}


.nav-links {
    margin: 0.5em;
    color: black;
    font-size: 18px;
    cursor: pointer;
}

.nav-links:hover{
    color: #0077b6;
}


.nav-links:active { 
    transform: scale(.98); 
} 


@media (max-width: 1400px) {
    .nav-img {
        height: 7em;
    }
    .nav-links-container {
        margin-right: 8em;
    }

    .nav-links{
        margin: 0.5em;
        color: black;
        font-size: 14px;
    }
    
}

@media (max-width: 992px) {
    .navBar-container {
        padding-left: 3em;
    }
    .nav-img {
        height: 6em;
    }
    .nav-links-container {
        margin-right: 6em;
    }
}

@media (max-width: 768px) {
    .navBar-container {
        padding-left: 2em;
        flex-direction: column;
        align-items: flex-start;
    }
    .nav-img {
        height: 5em;
    }
    .nav-links-container {
        margin-right: 4em;
        margin-top: 1em;
    }
    .nav-links {
        font-size: 16px;
    }
}

@media (max-width: 576px) {
    .navBar-container {
        padding-left: 1em;
    }
    .nav-img {
        height: 4em;
    }
    .nav-links-container {
        margin-right: 2em;
        margin-top: 1em;
    }
    .nav-links {
        font-size: 14px;
    }
}

@media (max-width: 400px) {
    .navBar-container {
        padding-left: 0.5em;
    }
    .nav-img {
        height: 3em;
    }
    .nav-links-container {
        margin-right: 1em;
        margin-top: 1em;
    }
    .nav-links {
        font-size: 12px;
    }
}