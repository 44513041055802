.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.5); 
}

.loading-container p {
    font-size: 1.5em;
    color: #333; /* Optional: customize the text color */
}

.spinner {
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-top: 5px solid blue;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
}

/* Define the spin animation */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}