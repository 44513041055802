.new-student-form {
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 1%;
    background-color: rgba(204, 204, 204, 0.5);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    border-radius: 50px;
}

.new-student-form-heading {
    font-size: 20px;
    font-weight: 500;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 1%;
    background-color: rgba(204, 204, 204, 0.5);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 50px;
    display: flex;
    flex-wrap: row;
    justify-content: space-between;
    flex-direction: row;
    text-align: left;
    align-items: center;
    color: #002549;
}

.student-info-non-edit{
    margin-left: 2em;
}

.header-buttons{
    display: flex;
    flex-direction: column;
    margin-top: 0.5em;
    margin-right: 2em;
}

.student-information-heading{
   font-weight: bolder;
   text-align: center;
}

.form-field {
    margin-top: 2em;
}

.new-student-form-button-section {
    display: flex;
    justify-content: center;
    float: row;
    margin-top: 2%;
}

.new-student-form-button {
    margin: 0 3% 3% 3%;
    height: 4em;
    width: 20em;
    cursor: pointer;
    border-radius: 50px;
    border: 1px solid #002549;
    color: white;
    background-color: #006699;
    font-weight: bolder;
    font-size: 14px;
}

.new-student-form-button:hover{
    margin: 0 3% 3% 3%;
    height: 4em;
    width: 20em;
    cursor: pointer;
    border-radius: 50px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.new-student-form-button:active { 
    transform: scale(.98); 
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
} 

.input-field {
    width: 20em;
    height: 2em;
    font-size: 18px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    border-bottom: 2px solid grey;
}

.input-field[type="date"] {
    padding: 0.5em;
    box-sizing: border-box;
    width: 100%;
    width: 20em;
    height: 2em;
}

.input-field:focus {
    outline: none !important;
    box-shadow: 0 0 10px gray;
}

.from-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-bottom: 5em;
    font-weight: bold;
}

.section-divider {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: .5em;
    align-items: center;
    font-size: 14px;
    line-height: 1.5rem;
    background-color: white;
}

.section-divider-container {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    text-decoration: underline;
    color: #002549;
}

.form-dropdown{
    margin-left: .5em;
    padding-top: .2em;
    padding-bottom: .2em;
    align-items: center;
    width: 15em;
    border-radius: 5px;
}

.payment-details-section{
    border: .5em solid white;
    margin-left: 5%;
    margin-right: 5%;
}

.payment-infromation-coloum{
    padding: 1em;
    font-weight: 700;
}

.installation-information{
    border-left: 1px solid black;
    border-right: 1px solid black;
    padding: 0.1em 2em 2em 2em;
}

.student-class-selection{
    margin-left: 3%;
}

.installment-table-school-form{
    margin: 1em;
}

.required-feild-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.required-field{
    color: red;
}

@media (max-width: 1400px) {
    .new-student-form, .new-student-form-heading {
        margin-left: 10%;
        margin-right: 10%
    }
    .input-field {
        width: 12em;
    }

    .input-field[type="date"] {
        /* Ensure date input fields match other input fields */
        padding: 0.5em;
        box-sizing: border-box;
        width: 100%;
        width: 12em;
        height: 2em;
    }
    
}

@media (max-width: 992px) {
    .new-student-form, .new-student-form-heading {
        margin-left: 2%;
        margin-right: 2%;
    }
    .input-field {
        width: 16em;
    }

    .input-field[type="date"] {
        /* Ensure date input fields match other input fields */
        padding: 0.5em;
        box-sizing: border-box;
        width: 100%;
        width: 16em;
        height: 2em;
    }
    .new-student-form-button {
        width: 18em;
    }
    .new-student-form-button:hover {
        width: 18em;
    }
}

@media (max-width: 768px) {
    .new-student-form, .new-student-form-heading {
        margin-left: 1%;
        margin-right: 1%;
    }
    .input-field {
        width: 14em;
    }

    .input-field[type="date"] {
        /* Ensure date input fields match other input fields */
        padding: 0.5em;
        box-sizing: border-box;
        width: 100%;
        width: 14em;
        height: 2em;
    }
    .new-student-form-button {
        width: 16em;
    }
    .new-student-form-button:hover {
        width: 16em;
    }
    .header-buttons {
        margin-right: 1em;
    }
    .student-info-non-edit {
        margin-left: 1em;
    }
}

@media (max-width: 576px) {
    .new-student-form, .new-student-form-heading {
        margin-left: 1%;
        margin-right: 1%;
        flex-direction: column;
    }
    .input-field {
        width: 12em;
    }

    .input-field[type="date"] {
        /* Ensure date input fields match other input fields */
        padding: 0.5em;
        box-sizing: border-box;
        width: 100%;
        width: 12em;
        height: 2em;
    }
    .new-student-form-button {
        width: 14em;
        height: 3em;
    }
    .new-student-form-button:hover {
        width: 14em;
        height: 3em;
    }
    .header-buttons {
        margin-right: 0.5em;
    }
    .student-info-non-edit {
        margin-left: 0.5em;
    }
}

@media (max-width: 400px) {
    .new-student-form, .new-student-form-heading {
        margin-left: 0.5%;
        margin-right: 0.5%;
        flex-direction: column;
    }
    .input-field {
        width: 10em;
    }

    .input-field[type="date"] {
        /* Ensure date input fields match other input fields */
        padding: 0.5em;
        box-sizing: border-box;
        width: 100%;
        width: 10em;
        height: 2em;
    }
    .new-student-form-button {
        width: 12em;
        height: 2.5em;
    }
    .new-student-form-button:hover {
        width: 12em;
        height: 2.5em;
    }
    .header-buttons {
        margin-right: 0.2em;
    }
    .student-info-non-edit {
        margin-left: 0.2em;
    }
}

