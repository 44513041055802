.status-message-container{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);  
    border-radius: 5%;
    padding: 8em 15em 8em 15em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-shadow: rgba(99, 99, 99, 0.8) 0px 2px 8px 0px;
    /* background-color: rgba(255, 255, 255, 0.1);  */
    background-color: rgba(204,204,204, 0.5);
}

.alert-button{
    margin-top: 2em;
    height: 3em;
    width: 10em;
}

@media (max-width: 1400px) {
    .status-message-container {
        padding: 6em 12em;
    }
    .alert-button {
        margin-top: 1.5em;
        height: 2.5em;
        width: 8em;
    }
}

@media (max-width: 992px) {
    .status-message-container {
        padding: 5em 10em;
    }
    .alert-button {
        margin-top: 1em;
        height: 2.5em;
        width: 8em;
    }
}

@media (max-width: 768px) {
    .status-message-container {
        padding: 4em 8em;
    }
    .alert-button {
        margin-top: 0.5em;
        height: 2.5em;
        width: 7em;
    }
}

@media (max-width: 576px) {
    .status-message-container {
        padding: 3em 6em;
    }
    .alert-button {
        margin-top: 0.5em;
        height: 2.5em;
        width: 6em;
    }
}

@media (max-width: 400px) {
    .status-message-container {
        padding: 2em 4em;
    }
    .alert-button {
        margin-top: 0.5em;
        height: 2.5em;
        width: 5em;
    }
}