.login-container{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    padding: 6em 10em 6em 10em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: rgba(204,204,204, 0.1);
}

.form-input-feild{
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #03045e;
}

.login-button{
    margin: 2em;
    height: 4em;
    width: 20em;
    cursor: pointer;
    border: 1px solid #0077b6;
    color: white;
    background-color: #0077b6;
    border-radius: 25px;
    box-shadow: (99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.login-button:hover{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.login-button:active { 
    transform: scale(.98); 
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
} 

.login-input-field {
    width: 30em;
    height: 2em;
    border: 2px inset gray !important;
    border-radius: 5px;
}

.login-demo-bg {
    opacity: 0.1;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width:30%;
    height: 50%;
    z-index: -1;
}

.fail-login-message{
    color: red;
}

@media (max-width: 1800px) {
    .login-container {
        padding: 7em 10em;
    }
    .login-input-field {
        width: 25em;
        height: 1.5em;
    }
    .login-button {
        height: 3em;
        width: 18em;
    }
    .login-demo-bg  {
        width: 40%; 
        height: 60%;
    }
}

@media (max-width: 1024px) {
    .login-container {
        padding: 1em 8em;
    }
    .login-input-field {
        width: 20em;
    }
    .login-button {
        height: 4em;
        width: 16em;
    }
    .login-demo-bg  {
        width: 30%; 
        height: 50%;
    }
}

@media (max-width: 768px) {
    .login-container {
        padding: 1em 8em;
    }
    .login-input-field {
        width: 18em;
    }
    .login-button {
        height: 3.5em;
        width: 14em;
    }
    .login-demo-bg  {
        width: 30%; 
        height: 50%;
    }
}

@media (max-width: 576px) {
    .login-container {
        padding: 1em 8em;
    }
    .login-input-field {
        width: 15em;
    }
    .login-button {
        height: 3em;
        width: 12em;
    }
    .login-demo-bg  {
        width: 50%; 
        height: 30%;
    }
}

@media (max-width: 400px) {
    .login-container {
        padding: 2em 4em;
    }
    .login-input-field {
        width: 12em;
    }
    .login-button {
        height: 2.5em;
        width: 10em;
    }
    .login-demo-bg  {
        width: 50%; 
        height: 30%;
    }
}