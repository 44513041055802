.student-option-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5%;
    width: 90%;
    max-width: 50em;
}

.student-id-class-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 2em;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: rgba(204, 204, 204, 0.2);
    width: 90%; 
    max-width: 30em;
    margin: 0 auto;
}

.student-option-input,
.option-page-dropdown {
    margin: 1.5em;
    padding: 0.5em;
    font-size: 18px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    border-bottom: 2px solid #0077b6;
}

.option-page-dropdown {
    background-color: white;
    border: 2px solid #0077b6;
    border-radius: 5px;
}

.option-page-button {
    /* width: 100%; */
    margin: 0.5em;
    padding: 1em;
    font-size: 18px;
    cursor: pointer;
    background-color: #0077b6;
    border: 1px solid #0077b6;
    color: white;
    border-radius: 50px;
    text-align: center;
}

.option-page-button:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.option-page-button:active {
    transform: scale(0.98);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.or-divider {
    margin: 1em;
}

.option-page-dropdown-label {
    margin: 5px;
    font-weight: bold;
}

.option-page-bg {
    opacity: 0.1;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width:30%;
    height: 50%;
    z-index: -1;
}

@media (max-width: 1400px) {
    .option-page-bg  {
        width: 70%; 
        height: 30%;
    }
}

@media (max-width: 992px) {
    .option-page-bg  {
        width: 80%; 
        height: 40%;
    }
}

@media (max-width: 768px) {
    .option-page-bg  {
        width: 100%; 
        height: 60%;
    }
}

@media (max-width: 576px) {
    .option-page-bg  {
        width: 100%; 
        height: 50%;
    }
}

@media (max-width: 400px) {
    .option-page-bg  {
        width: 100%; 
        height: 30%;
    }
}
