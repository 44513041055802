.container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5%;
    padding: 8em 15em;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.home-bg {
    opacity: 0.1;
    position: absolute;
    left: 51%;
    top: 55%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 80%;
    z-index: -1;
}

.home-page-button {
    margin: 5%;
    height: 5em; 
    width: 25em;
    cursor: pointer;
    border-radius: 50px;
    border: 2px solid grey;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    border: 1px solid #023e8a;
    color: white;
    background-color: #0077b6;
    font-weight: 600;
    font-size: 16px;
}

.home-page-button:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.home-page-button:active {
    transform: scale(0.98);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

@media (max-width: 1400px) {
    .container {
        padding: 6em 12em; 
    }
    .home-page-button {
        height: 4.5em;
        width: 18em;
    }
    .home-bg {
        width: 60%; 
        height: 70%;
    }
}

@media (max-width: 992px) {
    .container {
        padding: 5em 10em; 
    }
    .home-page-button {
        height: 4em;
        width: 16em;
    }
    .home-bg {
        width: 70%; 
        height: 60%;
    }
}

@media (max-width: 768px) {
    .container {
        padding: 4em 8em; 
    }
    .home-page-button {
        height: 4em;
        width: 25em;
    }
    .home-bg {
        width: 80%; 
        height: 50%;
    }
}

@media (max-width: 576px) {
    .container {
        padding: 3em 6em; 
    }
    .home-page-button {
        height: 4em;
        width: 20em;
    }
    .home-bg {
        width: 100%; 
        height: 50%; 
    }
}

@media (max-width: 400px) {
    .container {
        padding: 2em 4em;
    }
    .home-page-button {
        height: 3em;
        width: 15em;
    }
    .home-bg {
        width:100%; 
        height: 50%;
    }
}