.table-container {
  overflow-x: auto;
  overflow-y: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px !important;
}

th,
td {
  padding: 5px 3em 5px 3em;
  border: 1px solid #ddd;
  text-align: center;
  font-size: 18px;
  border-radius: 5px;
}

th {
  background-color: #002549;
  color: white;
  height: 3em;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

tbody tr:hover {
  background-color: #f1f1f1;
}

td {
  min-width: 10em;
}

@media (max-width: 1400px) {

  th,
  td {
    padding: 4px 1em;
    font-size: 12px;
  }
}

@media (max-width: 992px) {

  th,
  td {
    padding: 3px 1.5em;
    font-size: 14px;
  }
}

@media (max-width: 768px) {

  th,
  td {
    padding: 2px 1em;
    font-size: 12px;
  }

  table {
    font-size: 12px;
  }
}

@media (max-width: 576px) {

  th,
  td {
    padding: 1px 0.5em;
    font-size: 10px;
  }

  th {
    height: 2.5em;
  }
}

@media (max-width: 400px) {

  th,
  td {
    padding: 0.5px 0.25em;
    font-size: 8px;
  }

  th {
    height: 2em;
  }
}