.student-data-container {
    margin-top: 9em;
}

.student-data-bg {
    opacity: 0.1;
    position: fixed;
    left: 25%;
    top: 20%;
    width:50%;
    height: 80%;
    z-index: -1;
}

@media (max-width: 1400px) {
    .student-data-container   {
        margin-top: 8em;
    }
}

@media (max-width: 992px) {
    .student-data-container   {
        margin-top: 6em;
    }
}

@media (max-width: 768px) {
    .student-data-container   {
        margin-top: 5em;
    }
}

@media (max-width: 576px) {
    .student-data-container   {
        margin-top: 4em;
    }
}

@media (max-width: 400px) {
    .student-data-container   {
        margin-top: 3em;
    }
}